<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">
        <span class="font-weight-bold" v-if="active == 1">Sửa phiếu đăng ký học tập</span>
        <span class="font-weight-bold" v-if="active == 2">Hoàn tất phiếu đăng ký học tập</span>
        <span class="font-weight-bold" v-if="active == 3">Gửi phiếu đăng ký học tập</span>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <el-steps :active="active" align-center>
            <el-step title="Tạo phiếu đăng ký học tập"></el-step>
            <el-step title="Hoàn tất phiếu đăng ký học tập"></el-step>
            <el-step title="Gửi phiếu đăng ký học tập"></el-step>
          </el-steps>
        </div>
      </div>
      <div class="mt-6">
        <RegisterStudyForm
            :errorsForm="errorsForm"
            v-if="active === 1"
            :contract_prop="contract"
            @dataContract="dataContract"
        ></RegisterStudyForm>
        <CompletedStudyForm
            :contract="contract"
            v-if="active === 2"></CompletedStudyForm>
        <CustomerConfirmForm
            :contract="contract"
            v-if="active === 3"
            :url_copy="url_copy"
            :url_confirm_contract="url_confirm_contract"
        ></CustomerConfirmForm>
      </div>
    </div>
    <div class="card-header d-flex justify-content-end">
      <button class="btn btn-default mr-2" v-if="active > 1" @click="back">Quay lại</button>
      <button class="btn btn-success" v-if="active === 1" :disabled="is_loading_submit" @click="updateContract"><i
          v-if="is_loading_submit"
          class="el-icon-loading"></i>Cập nhật
      </button>
      <button class="btn btn-success" v-if="active === 2" :disabled="is_loading_submit" @click="completedStudy"><i
          v-if="is_loading_submit"
          class="el-icon-loading"></i>Hoàn tất
      </button>
      <button class="btn btn-success" v-if="active === 3" :disabled="is_loading_submit" @click="confirm"><i
          v-if="is_loading_submit"
          class="el-icon-loading"></i>Xác
        nhận
      </button>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

import Multiselect from "vue-multiselect";
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import RegisterStudyForm from "@/views/pages/customer/components/RegisterStudyForm";
import CompletedStudyForm from "@/views/pages/customer/components/CompletedStudyForm";
import CustomerConfirmForm from "@/views/pages/customer/components/CustomerConfirmForm";
import {
  CONTRACT_ONLINE_GET_BY_ID, CONTRACT_ONLINE_GET_SHORT_LINK, CONTRACT_ONLINE_UPDATE
} from "@/core/services/store/contract/contractOnline.module";
import {CUSTOMER_UPDATE_LEVEL} from "@/core/services/store/customer/customer.module";
import {LEVEL_3A} from "@/core/config/customer/customerOption";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {CAMBRIDGE, ECOACH, IELTS, TOEIC} from "../../../core/config/center/centerOption";

const _ = deepdash(lodash);
export default {
  name: "EditContractEdutalk",
  props: {},
  components: {
    CustomerConfirmForm,
    CompletedStudyForm,
    RegisterStudyForm,
    Multiselect
  },
  data() {
    return {
      errorsForm: {},
      active: 1,
      is_loading_submit: false,
      contract: {
        id: '',
        uuid: '',
        uuid_secret: '',
        is_confirm: 0,
        tuition_payment_id: '',
        is_older: true,
        name: '',
        phone: '',
        phone_other: '',
        email: '',
        birthday: '',
        center_id: '',
        branch_id: '',
        customer_id: this.$route.query.customer_id,
        total: '',
        total_need: 0,
        total_promotions: 0,
        courses: [
          {
            id: "",
            name: "",
            fee: 0
          }
        ],
        list_promotions: [],
        parent: {
          type: 'bo',
          other_value: '',
          name: '',
          phone: '',
          phone_other: '',
        }
      },
      url_copy: '',
      url_confirm_contract: ''
    }
  },
  watch: {
    contract() {
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Danh sách hợp đồng tư vấn"},
      {title: "Sửa phiếu đăng ký học tập", icon: 'far fa-user'}
    ]);
    let id = this.$route.params.contract_id;
    this.getContractById(id);
  },
  methods: {
    dataContract(data) {
      this.contract = data;
    },
    updateContract() {
      this.is_loading_submit = true;
      this.contract.confirm = 0;
      this.contract.policy = [];
      this.$store.dispatch(CONTRACT_ONLINE_UPDATE, this.contract).then((data) => {
        this.$message.success(data.message);
        this.contract.id = data.data.id;
        this.contract.uuid = data.data.uuid;
        this.contract.uuid_secret = data.data.uuid_secret;
        this.next();
        this.changeLink();
        this.getContractById(this.contract.id);
          // this.confirmCustomer();
      }).catch((e) => {
		  this.$message.error(e.data.message);
        if (e.data.data.message_validate_form) {
          this.errorsForm = e.data.data.message_validate_form;
        }
      }).finally(() => {
        this.is_loading_submit = false;
      })
    },
    confirm() {
      this.$router.push({
        name: "contract-index"
      })
    },
    changeLink() {
      let path_url_copy = `/xac-thuc?code=${this.contract.uuid}`;
      let path_url_confirm_contract = `/xac-thuc?code=${this.contract.uuid}&check=${this.contract.uuid_secret}`;
      let domain = '';
      switch (this.contract.center_id) {
        case ECOACH:
          domain = `${process.env.VUE_APP_BASE_URL_ECOACH}`;
          break;
        case IELTS:
        case CAMBRIDGE:
          domain = `${process.env.VUE_APP_BASE_URL_IELTS}`;
          break;
        case TOEIC:
          domain = `${process.env.VUE_APP_BASE_URL_TOEIC_MASTER}`;
          break;
        case IELTS_CAMBRIDGE:
          domain = `${process.env.VUE_APP_BASE_URL_IELTS}`;
          break;
      }
      this.url_copy = this.getShortLinkUrlCopy(domain + path_url_copy);
      this.url_confirm_contract = this.getShortLinkConfirm(domain + path_url_confirm_contract);
    },
    getShortLinkUrlCopy(url) {
      this.$store.dispatch(CONTRACT_ONLINE_GET_SHORT_LINK, {
        url: url,
        center_id: this.contract.center_id
      }).then((data) => {
        this.url_copy = data.data;
      });
    },
    getShortLinkConfirm(url) {
      this.$store.dispatch(CONTRACT_ONLINE_GET_SHORT_LINK, {
        url: url,
        center_id: this.contract.center_id
      }).then((data) => {
        this.url_confirm_contract = data.data;
      });
    },
    completedStudy() {
      this.next();
    },
    confirmCustomer() {
      this.$store.dispatch(CUSTOMER_UPDATE_LEVEL, {
        customer_id: this.contract.customer_id,
        level: LEVEL_3A
      }).then(() => {
        this.$message.success('Xác nhận thành công');
      }).catch((e) => {
        this.$message.error(e.data.message);
      })
    },
    next() {
      if (this.active++ > 2) this.active = 1;
    },
    back() {
      if (this.active-- === 0) this.active = 1;
      if (this.contract.id) {
        this.getContractById(this.contract.id);
      }
    },
    getContractById(id) {
      this.$store.dispatch(CONTRACT_ONLINE_GET_BY_ID, id).then((data) => {
        this.contract = data.data;
      })
    },
  },
  computed: {
    ...mapGetters(['currentUser'])
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.withdraw-course {
  position: absolute;
  right: 20px;
  top: 37px;
}
</style>
